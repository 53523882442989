<template lang="pug">
  div(@click="$emit('action')" :style="css").wrap-item-bottom-float-btn.f.fh
    span.bold {{label}}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-bottom-float-btn {
  border-radius: 6px;
  margin: 0 auto;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  span {
    color: #fff;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    height: {
      type: Number | String
    },
    width: {
      type: Number | String
    },
    hexColor: {
      type: String
    }
  },
  data () {
    return {
      css: {
        width: '240px',
        height: '32px',
        backgroundColor: ''
      }
    }
  },
  watch: {
    width: {
      handler: function (val) {
        this.css.width = String(val) + 'px'
      },
      immediate: true
    },
    height: {
      handler: function (val) {
        this.css.height = String(val) + 'px'
      },
      immediate: true
    },
    hexColor: {
      handler: function (val) {
        if (val) {
          this.css.backgroundColor = val
        } else {
          this.css.backgroundColor = '#e7305b'
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {

  },
  methods: {

  }
}
</script>
