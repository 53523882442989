<template lang="pug">
  Auth(@loggedIn="onLoggedIn" @loginFailed="loginFailed")
    NowLoading(:show="showNowLoading")
    div
      Header(:content="header")
    div.wrap-create-order.f
      ItemProgressBar(v-if="currentIndex !== 4" :activeNumber="currentIndex" @emit-index="changeCurrentIndex")
      ModuleOrderPlace.create-order(v-if="currentIndex === 0 && order.place" :opts="opts" :place="order.place" @place="changePlace" @prev="changeCurrentIndex" @next="changeCurrentIndex")
      ModuleOrderDescription.create-order(v-if="currentIndex === 1" :tags="order.tags" :description="order.description" @tag="changeTags"  @description="changeDescription" @prev="changeCurrentIndex" @next="changeCurrentIndex")
      ModuleOrderBudget.create-order(v-if="currentIndex === 2" :budget="order.budget" :proppedAgencyFee="order.agencyFee" @budget="changeBudget" @agency-fee="changeAgencyFee" @prev="changeCurrentIndex" @next="changeCurrentIndex")
      ModuleOrderConfirm.create-order(v-if="currentIndex === 3" :order="order" @cash="changePayOnlyCash" @prev="changeCurrentIndex" @change-page="changeCurrentIndex" @payment="toStripePayment")
      ModuleOrderCheckPayment.create-order(v-if="currentIndex === 4")
      v-dialog(v-model="isShowDialog" overlay-color="#000000" overlay-opacity="0.6")
        v-card.pa-4
          div.mx-8(v-if="dialogType=='selectPaymentMethods'")
            p.fz18.bold.mt-2.text-center お支払い方法の選択
            div.f.fh.mt-8.mb-8
              v-btn(color="#E7305B" width="176" @click="toStripePayment")
                span.bold(style="color:#fff;" width="176") カードでお支払い
            //- div.f.fh.mt-4
            //-   v-btn(color="#E7305B" width="176" @click="toPayment")
            //-     span.bold(style="color:#fff;") PayPayでお支払い
            //- div.f.fh.mt-4.mb-4
            //-   v-btn(color="#E7305B"  width="176" @click="toPayment")
            //-     span.bold(style="color:#fff;") LINEPayでお支払い
          div.stripe-wrapper(v-if="dialogType=='stripePayment'")
            p.fz18.bold.mt-2.text-center カード情報の入力
            div
              p.fz14.mr-2.bold.gray.mb-3 合計
              p.text-center(style="font-size: 36px; font-weight: bold;") {{ displayTotalAmount() }}
                span.fz14 円
              p.fz12.px-3.mb4.gray(v-if="!order.payOnlyCash") ・このお金は実際には支払われず、支払い能力確認のため一時的にカード利用枠を消費します
              p.fz12.px-3.mb4.gray(v-if="!order.payOnlyCash") ・ご依頼が完了した際や、マッチせずに依頼を削除された際に返金されます
              p.fz12.px-3.mb4.gray(v-if="order.payOnlyCash") ・ご依頼がマッチせずに依頼を削除された際は返金されます
              p.fz12.px-3.mb8.gray ・実際のお支払額は、以下となります。
              p.fz12.mb2.gray.text-center.bold {{ `システム利用料：${ order.serviceFee }円${order.payOnlyCash ? '(カード払い)' : ''}` }}
              p.fz12.mb2.gray.text-center.bold {{ `+ 代行料金： ${order.agencyFee}円${order.payOnlyCash ? '(カード払い)' : ''}` }}
              p.fz12.gray.text-center.bold(:style="`margin-bottom:${!order.payOnlyCash || usedPoint > 0 || toUseCoupon ? '2px' : '24px'};`") {{ `+ 実際の買物代金${order.payOnlyCash ? '(現金払い)' : ''}` }}
              p.fz12.gray.text-center.bold(v-if="!order.payOnlyCash" :style="`margin-bottom:${usedPoint > 0 || toUseCoupon ? '2px' : '24px'};`") + 買物手数料(買物代金の9%)
              p.fz12.gray.text-center.bold(v-if="toUseCoupon" :style="`margin-bottom:${usedPoint > 0 ? '2px' : '24px'};`") {{ ` - クーポン割引：${ couponAmount }円` }}
              p.fz12.mb-6.gray.text-center.bold(v-if="usedPoint > 0") {{ ` - 使用ポイント：${ usedPoint }円` }}
            p.fz14.bold.mb0.mt-2(v-if="point > 0 && order.payOnlyCash") ポイントを使う
            div.point(v-if="point > 0 && order.payOnlyCash")
              p.bold.fz14.mb2.ml-4 残り：
                span.bold.fz20.ml-1 {{ point - usedPoint }}
              div.f.fc
                input.mr-4(type="number" v-model="inputPoint")
                div.f.flex-middle.mx-2
                  div(style="width: 24px; height: 24px;")
                    v-icon(color="#02b517" @click="decidePoint") mdi-check-bold
                  div(style="width: 24px; height: 24px;")
                    v-icon.ml-4(color="#f7165a" @click="cancelPoint") mdi-close-thick
            p.fz14.bold.mb0.mt-2(v-if="coupons && coupons.length > 0") クーポンを利用する
            v-checkbox(v-if="coupons && coupons.length > 0" v-model="toUseCoupon" :label="couponLabelText")
            ItemStripeCardElement(:order="order" :paymentType="'prePayment'" :canPayWithoutStripe="canPayWithoutTransaction" @stripe="afterStripePayment" @no-pay="afterCouponPayment")
          //- div(v-if="dialogType=='paypayPayment'")
          //-   p.fz18.bold.mt-2.text-center(v-if="isShowLoading") 決済リンク生成中
          //-   p.fz18.bold.mt-2.text-center(v-else) 決済ページへ
          //-   div.ma-2
          //-     MinNowLoading.mb-4(:show="isShowLoading")
          //-     div.f.fh.mt-8.mb-8(v-if="!isShowLoading")
          //-       v-btn(color="#E7305B")
          //-         span.bold(style="color:#fff;" @click="toPayment") PayPayでお支払い
          div(v-if="dialogType=='completePayment'")
            p.fz18.bold.mt-2.text-center 募集が完了しました！
            div.ma-2
              div.px-2
                p.text-center.mb-0 お支払いが完了し、ご依頼を公開しました！
                p.text-center マッチングまで少々お待ちください。
              div.f.fh.mt-6.mb-2
                v-btn(color="#E7305B")
                  span.bold(style="color:#fff;" @click="toMypage") マイページへ
    Footer

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-create-order {
  padding-top: 48px;
  padding-bottom: 62px;
  overflow-y: scroll;
  width: 100%;
  min-height: 100vh;
  .create-order {
    margin: 0 auto;
    max-width: $base_max_width_px;
  }
}

.dialog-card {
  width: 260px;
}

.point {
    span {
      color: $text_sub;
    }

    input {
      border-radius: 8px;
      border: solid 2px $border_color;
      padding: 4px 16px;
      font-size: 24px;
      color: $text_sub;
      font-weight: bold;
      width: 200px;
    }

    .v-icon {
      cursor: pointer;
    }
  }

.stripe-wrapper {
  min-width: 300px;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import firebase, { functions } from '@/components/utils/firebase'
import { gmapApi } from 'vue2-google-maps'
import Auth from '@/components/shared/Auth'
import NowLoading from '@/components/shared/NowLoading'
import MinNowLoading from '@/components/shared/MinNowLoading'
import Header from '@/components/shared/Header'
import Footer from '@/components/shared/Footer'
import ModuleOrderPlace from '@/components/module/ModuleOrderPlace'
import ModuleOrderDescription from '@/components/module/ModuleOrderDescription'
import ModuleOrderBudget from '@/components/module/ModuleOrderBudget'
import ModuleOrderConfirm from '@/components/module/ModuleOrderConfirm'
import ModuleOrderCheckPayment from '@/components/module/ModuleOrderCheckPayment'
import ItemStripeCardElement from '@/components/item/ItemStripeCardElement'
import ItemProgressBar from '@/components/item/ItemProgressBar'
import { database } from '../../functions/shared/database'
import liff from '@line/liff'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    NowLoading,
    MinNowLoading,
    Header,
    Footer,
    ModuleOrderPlace,
    ModuleOrderBudget,
    ModuleOrderDescription,
    ModuleOrderConfirm,
    ModuleOrderCheckPayment,
    ItemStripeCardElement,
    ItemProgressBar
  },
  data: () => ({
    header: {
      // title: '募集の作成',
      image: '/img/logo-horizontal.png',
      right: {
        icon: 'mdi-account-circle',
        iconText: 'マイページ',
        to: '/mypage'
      }
    },
    order: {
      id: null,
      // category: null,
      // time: null,
      place: null,
      tags: [],
      budget: 0,
      serviceFee: 300,
      agencyFee: 500,
      usedCouponAmount: 0,
      usedPoint: 0,
      description: '',
      payOnlyCash: false
    },
    currentIndex: 0,
    point: 0,
    usedPoint: 0,
    inputPoint: 0,
    coupons: null,
    couponLabelText: null,
    couponAmount: 0,
    usedCoupons: [],
    toUseCoupon: false,
    canPayWithoutTransaction: false,
    showNowLoading: true,
    geocoder: null,
    isValidOrder: false,
    opts: [],
    orderId: null,
    isShowDialog: false,
    dialogType: '',
    isShowLoading: false,
    paymentUrl: null
  }),
  computed: {
    ...mapStateAuth(['isLoggedIn', 'uid'])
  },
  watch: {
    order: {
      handler: function (val, oldVal) {
        if (
          val.place &&
          val.agencyFee &&
          val.agencyFee >= 100 &&
          val.budget &&
          val.budget > 0
        ) {
          this.isValidOrder = true
        } else {
          this.isValidOrder = false
        }
        if (oldVal) {
          if (!val.payOnlyCash && oldVal.payOnlyCash && this.usedPoint > 0) {
            this.usedPoint = 0
          }
        }
      },
      immediate: true,
      deep: true
    },
    toUseCoupon: function (val) {
      if (val && this.usedPoint > 0) {
        const totalAmount = this.order.serviceFee + this.order.agencyFee
        if (this.usedPoint > totalAmount - this.couponAmount) {
          this.usedPoint -= totalAmount - this.couponAmount
        }
      }
      this.order.usedCouponAmount = val ? this.couponAmount : 0
      this.checkCanPayWithoutTransaction()
    },
    usedPoint: function (val) {
      this.order.usedPoint = val
      this.checkCanPayWithoutTransaction()
    },
    isShowDialog: async function (val) {
      if (!val && this.dialogType === 'stripePayment' && this.currentIndex !== '4') {
        await database.accessLogCollection(this.uid).add({
          uid: this.uid,
          page: '/create-order',
          component: 'closeStripePayment',
          accessedAt: new Date()
        })
      }
    }
  },
  async mounted () {
    await this.$gmapApiPromiseLazy().then(() => {
      this.geocoder = new google.maps.Geocoder()
    })
    if (this.$route.params.orderId) {
      this.currentIndex = 3
    }
    this.order.id = database.orderCollection().collection.doc().id
  },
  methods: {
    async loginFailed () {
      this.showNowLoading = false
      this.$router.push('/sign-in')
    },
    async onLoggedIn () {
      this.showNowLoading = false
      await this.createOpts()
    },
    async createOpts () {
      if (this.opts.length !== 0) return
      const addresses = await database.addressCollection(this.uid).all()
      const sortedAddresses = addresses.sort((a, b) => {
        if (a.isMain && !b.isMain) {
          return -1
        } else {
          return 1
        }
      })
      this.opts = sortedAddresses.map((address, index) => {
        if (address.isMain) {
          return {
            value: { address: address.address, type: '登録住所に呼ぶ', lat: address.lat, lng: address.lng },
            text: '登録住所に呼ぶ',
            id: address.id,
            subText: address.address,
            active: true
          }
        } else {
          return {
            value: { address: address.address, type: `住所${String(index + 2)}に呼ぶ`, lat: address.lat, lng: address.lng },
            text: `住所${String(index + 2)}に呼ぶ`,
            subText: addresses.address,
            active: false
          }
        }
      })
      this.order.place = this.opts[0]

      this.point = await database.userCollection().findById(this.uid).then(user => {
        if (user.point && user.point > 0) {
          return user.point
        } else {
          return 0
        }
      })

      const coupons = await database.couponCollection(this.uid).getValidCoupon()
      if (coupons) {
        const now = new Date()
        const uid = this.uid
        const validCoupons = coupons.filter(async coupon => {
          if (!coupon.expireDate) return true
          const expireDate = coupon.expireDate.toDate()
          if (expireDate.getTime() <= now) {
            await database.couponCollection(uid).update(coupon.id, {
              isValid: false
            })
          } else {
            return true
          }
        })
        validCoupons.sort((a, b) => {
          if (a.expireDate.toDate().getTime() <= b.expireDate.toDate().getTime()) {
            return 1
          } else {
            return -1
          }
        })
        this.coupons = validCoupons

        let index = 0
        while (this.couponAmount < this.order.serviceFee && index < this.coupons.length) {
          this.usedCoupons.push(this.coupons[index])
          this.couponAmount += this.coupons[index].deposit
          index += 1
        }
        this.couponLabelText = `クーポンでシステム利用料を${this.couponAmount <= this.order.serviceFee ? this.order.serviceFee - this.couponAmount : 0}円にする`
      }
    },
    decidePoint () {
      let point = 0
      let totalAmount = this.order.serviceFee + this.order.agencyFee
      if (this.order.toUseCoupon) totalAmount -= this.couponAmount
      if (!this.order.payOnlyCash) totalAmount += Math.floor(this.order.budget * 1.09)

      // validation
      if (this.inputPoint < 0) {
        this.usedPoint = 0
        this.inputPoint = 0
      }

      // totalAmountを超えないように
      if (this.point < this.inputPoint) {
        point = this.point
      } else {
        point = this.inputPoint
      }

      if (point > totalAmount) {
        point = totalAmount
      }
      this.usedPoint = point
      this.inputPoint = 0
    },
    cancelPoint () {
      this.usedPoint = 0
      this.inputPoint = 0
    },
    checkCanPayWithoutTransaction () {
      let totalAmount = this.order.serviceFee + this.order.agencyFee - this.usedPoint
      if (this.order.toUseCoupon) totalAmount -= this.couponAmount
      if (!this.order.payOnlyCash) totalAmount += Math.floor(this.order.budget * 1.09)
      this.canPayWithoutTransaction = totalAmount === 0
    },
    changeDescription (description) {
      this.order.description = description
    },
    changePlace (place) {
      this.order.place = place
      const index = this.opts.findIndex(e => e.text === place.text)
      const newOpts = this.opts.map(e => {
        e.active = false
        return e
      })
      if (index >= 0) {
        newOpts[index].active = true
      }
      this.opts = newOpts
    },
    changeTags (tag) {
      const index = this.order.tags.indexOf(tag)
      if (index >= 0) {
        this.order.tags.splice(index, 1)
      } else {
        this.order.tags.push(tag)
      }
    },
    changeBudget (budget) {
      this.order.budget = budget
    },
    changeAgencyFee (agencyFee) {
      this.order.agencyFee = agencyFee
    },
    changePayOnlyCash (payOnlyCash) {
      this.order.payOnlyCash = payOnlyCash
    },
    async changeCurrentIndex (index) {
      if (index >= 1 && !this.order.place) {
        return
      }
      // if (index >= 2 && (!this.order.category || !this.order.time)) {
      //   return
      // }
      if (index >= 3 && !this.isValidOrder) {
        return
      }
      this.currentIndex = index
      await this.saveAccessLog()
      if (index === 0) {
        this.$delete(this.header, 'left')
      } else {
        const left = {
          icon: 'mdi-chevron-left',
          action: this.backIndex
        }
        this.$set(this.header, 'left', left)
      }
    },
    // async confirm () {
    //   this.dialogType = 'selectPaymentMethods'
    //   this.isShowDialog = true
    // },
    async toStripePayment () {
      this.isShowDialog = true
      this.dialogType = 'stripePayment'
      await database.accessLogCollection(this.uid).add({
        uid: this.uid,
        page: '/create-order',
        component: 'stripePayment',
        accessedAt: new Date()
      })
    },
    async afterStripePayment (paymentObject) {
      if (paymentObject.paymentIntentId === 'lessThanPay') {
        this.isShowDialog = false
        return
      }
      this.saveOrder('stripe', paymentObject)
    },
    async afterCouponPayment (paymentObject) {
      this.saveOrder('no-pay', paymentObject)
    },
    // async createPayPayPayment () {
    //   this.isShowLoading = true
    //   const userAgent = navigator.userAgent

    //   const req = {
    //     orderId: this.order.id,
    //     type: 'servicePayment',
    //     merchantPaymentId: this.order.id,
    //     amount: this.order.serviceFee + Number(this.order.agencyFee),
    //     orderDescription: 'システム利用料 + 代行費',
    //     userAgent: userAgent
    //   }

    //   const createQRCode = functions.httpsCallable('createPayPayQRCode')
    //   createQRCode({ req }).then((res) => {
    //     const status = res.data.status
    //     if (status === 201) {
    //       const result = res.data.body.data
    //       this.paymentUrl = result.url
    //       this.isShowLoading = false
    //     }
    //   })
    // },
    backIndex () {
      const index = this.currentIndex -= 1
      this.changeCurrentIndex(index)
    },
    toPayment () {
      this.isShowDialog = false
      window.location.href = this.paymentUrl
    },
    displayTotalAmount () {
      if (this.order.payOnlyCash) {
        return this.order.serviceFee + this.order.agencyFee - this.order.usedCouponAmount - this.order.usedPoint
      } else {
        return this.order.serviceFee + this.order.agencyFee + Math.floor(this.order.budget * 1.09) - this.order.usedCouponAmount
      }
    },
    async saveOrder (paymentType, option = null) {
      const data = {
        createdAt: new Date(),
        customer: this.uid,
        address: this.order.place.value.address,
        lat: this.order.place.value.lat,
        lng: this.order.place.value.lng,
        category: '買物代行',
        budget: this.order.budget,
        payOnlyCash: this.order.payOnlyCash,
        serviceFee: this.order.serviceFee,
        agencyFee: Number(this.order.agencyFee),
        orderId: this.order.id,
        status: 'created',
        worker: '',
        prePaymentType: paymentType,
        orderType: 'come-on'
      }

      if (this.order.tags.length > 0) data.tags = this.order.tags
      if (this.order.description.match(/\S/g)) data.description = this.order.description

      if (option) {
        if (paymentType === 'stripe') {
          data.status = 'open'
          if (option.paymentIntentId) {
            data.servicePaymentId = option.paymentIntentId
          }
        } else if (paymentType === 'no-pay') {
          data.status = 'open'
        }
        if (this.toUseCoupon) {
          const now = new Date()
          data.servicePaymentCoupon = this.couponAmount
          for (const coupon of this.usedCoupons) {
            if (this.couponAmount > 0) {
              const usedAts = coupon.usedAts ? coupon.usedAts : []
              const usedAmounts = coupon.usedAmounts ? coupon.usedAmounts : []
              const usedOrders = coupon.usedOrders ? coupon.usedOrders : []
              usedAts.push(now)
              usedOrders.push(data.orderId)
              if (this.couponAmount - coupon.deposit >= 0) {
                usedAmounts.push(coupon.deposit)
                await database.couponCollection(this.uid).update(coupon.id, {
                  isValid: false,
                  isConsumed: true,
                  usedOrders: usedOrders,
                  usedAmounts: usedAmounts,
                  beforeUsed: coupon.deposit,
                  deposit: 0,
                  usedAts: usedAts,
                  runOutAt: now
                })
              } else {
                usedAmounts.push(this.couponAmount)
                await database.couponCollection(this.uid).update(coupon.id, {
                  deposit: coupon.deposit - this.couponAmount,
                  usedOrders: usedOrders,
                  usedAmounts: usedAmounts,
                  beforeUsed: this.couponAmount,
                  usedAts: usedAts
                })
              }
              this.couponAmount -= coupon.deposit
            }
          }
        }
        if (this.usedPoint > 0) {
          data.usedPoint = this.usedPoint
        }
      }

      await database.orderCollection().set(this.order.id, data)

      this.dialogType = 'completePayment'
    },
    toMypage () {
      this.$router.push('/mypage')
    },
    async saveAccessLog () {
      let component
      if (this.currentIndex === 0) component = 'orderPlace'
      if (this.currentIndex === 1) component = 'orderDescription'
      if (this.currentIndex === 2) component = 'orderBudget'
      if (this.currentIndex === 3) component = 'orderConfirm'
      if (this.currentIndex === 4) component = 'orderCheckPayment'
      if (component) {
        await database.accessLogCollection(this.uid).add({
          uid: this.uid,
          page: '/create-order',
          component: component,
          accessedAt: new Date()
        })
      }
    }
    // async getCurrentPlace () {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(this.gpsGetSuccess, this.gpsGetError)
    //   } else { // 現在位置を取得できない場合の処理
    //     window.alert('あなたの端末では、現在位置を取得できません。')
    //   }
    // },
    // 現在位置が取得できた時に実行
    // async gpsGetSuccess (position) {
    //   const data = position.coords
    //   const lat = data.latitude
    //   const lng = data.longitude
    //   const latlng = new google.maps.LatLng(lat, lng)
    //   const self = this
    //   await this.geocoder.geocode({ latLng: latlng }, async function (results, status) {
    //     if (status === 'OK') {
    //       const value = {
    //         address: results[0].formatted_address.substring(13),
    //         type: '現在地に呼ぶ',
    //         lat: lat,
    //         lng: lng
    //       }
    //       const opt = {
    //         value: value,
    //         text: '現在地に呼ぶ',
    //         subText: value.address,
    //         height: 64,
    //         active: false
    //       }
    //       self.opts.push(opt)
    //     }
    //   })
    // },
    // // 現在位置の取得に失敗した時に実行
    // gpsGetError (err) {
    //   const errorMessage = {
    //     0: '原因不明のエラーが発生しました。',
    //     1: '位置情報が許可されませんでした。',
    //     2: '位置情報が取得できませんでした。',
    //     3: 'タイムアウトしました。'
    //   }
    //   window.alert(errorMessage[err.code])
    // }
  }
}
</script>
