<template lang="pug">
  div.wrap-module-order-budget.fc.f
    div.order-budget
      div.order-step-text.text-center
        p.mb-0 Step3
      div.order-budget-text
        div.text-center.mt-2
          p.mb-0 カモンさんが支払う
            span 商品代金
            | の目安と、
          p.mb-3
            span 代行料金
            | を指定してください。
      div.mt-8.mb-3.f.fc
        div.f.fc.order-budget-label
          p.bold.fz18.mb-0.mr-2 商品代金
          p.needed.fz14.bold.mb-1.px-2.py1 必須
      div.order-budget-input.f.fc.flex-bottom
        div.mr-2
          input.text-left(type="number" v-model="inputBudget")
        p.fz18.mb-0 円程度
      p.order-budget-note.fz13.mt-3.bold.text-center.mb-12 ※カモンさんが代金を立て替えられるかの重要な質問です
      div.order-agency-fee-label-wrapper.mt-8.mb-7.f.fc
        div.f.order-agency-fee-label.fc
          p.bold.fz18.mr-2.mb-0 カモンさんへの代行料金(100円以上)
          p.needed.fz14.bold.mb-1.px-2.py1 必須
      div.order-agency-fee
        div.order-agency-fee-accent.f.fc.mx-10
          div.long-allow.ml-5
            img(src="@/assets/long-arrow.png")
          div.text-center
            p.fz14.bold.mb-0 高いほど、
            p.fz14.bold.mb-0 マッチしやすい!
        div.order-agency-fee-select.f.fc.flex-middle.mb-3
          div.circle-min.text-center.mr-2.f.fh(@click="selectAgencyFee('min')" :class="minIsSelected ? pink : gray")
            p.mb-0 ¥100
          div.circle-mid.text-center.mr-2.f.fh(@click="selectAgencyFee('mid')" :class="midIsSelected ? pink : gray")
            p.mb-0 ¥500
          div.circle.text-center.f.fh(@click="selectAgencyFee('big')" :class="bigIsSelected ? pink : gray")
            p.mb-0 ¥1000
        div.agency-fee-input.text-center.mt-3.mb-0.f.fc.flex-middle
          p.mr-3.mb-0 その他の金額
          input.agency-fee-input(v-model="inputAgencyFee" placeholder="入力する" @click="()=>{this.isValidAgencyFee = true}" @blur="validateInputAgencyFee" @keypress.enter="validateInputAgencyFee" :class="inputAgencyFee ? isSelectedInput : isNotSelectedInput")
        p.agency-fee-alert.mt-2(v-if="!isValidAgencyFee") 代行費は100円以上でご入力ください。
      div.f.fh.mt-10.mb-15
        div.rounded-btn-back(@click="prev")
          p 戻る
        div.rounded-btn-active(v-if="budget > 0 && agencyFee >= 100" @click="next")
          p 次へ
        div.rounded-btn-inactive(v-else)
          p 次へ

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-order-budget {
  width: 100%;
  max-width: $base_max_width_px;
  margin-top: 100px !important;

  .order-budget-text-pink {
    background-color: $body_sub;
    color: #fff;
    font-family: 'Kiwi Maru', serif;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    max-width: $base_max_width_px;
  }

  .order-budget {
    width: 100%;
    max-width: $base_max_width_px;

    .order-step-text {
      font-size: 22px;
      font-weight: bold;
      color: $text_sub;
      font-family: 'Kiwi Maru', serif;
    }

    .order-budget-text {
      font-size: 18px;
      font-weight: bold;
      color: $text_primary;
      font-family: 'Kiwi Maru', serif;

      span {
        color: $text_sub;
      }
    }
  }
  .order-budget-label {
    width: 124px;
    border-bottom: 2px solid $body_main;
  }

  .needed {
    background-color: $body_sub;
    color: #fff;
  }

  .order-budget-input {
    max-width: $base_max_width_px;

    p {
      font-size: 18px;
      font-weight: bold;
      font-family: 'Kiwi Maru', serif;
    }

    input {
      border:2px solid $border_color;
      border-radius: 8px;
      padding: 8px 16px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      font-family: 'Kiwi Maru', serif;
      height: 48px;
      width: 160px;
      color: $text_main;
    }

    .gray {
      color: $text_secondary !important;
    }

    .pink {
      background-color: $body_sub !important;
      color: #fff !important;
    }
  }

  .order-budget-note {
    font-family: 'Kiwi Maru', serif;
  }

  .order-agency-fee-label {
    width: 360px;
    border-bottom: 2px solid $body_main;
  }

  .order-agency-fee-accent {
    .long-allow {
      height: 48px;
      min-width: 200px;
      max-width: 65%;
    }

    p {
      position: relative;
      top: -8px;
      left: -8px;
      font-family: 'Kiwi Maru', serif;
      color: $text_sub;
    }
  }

  .order-agency-fee-select {
    max-width: $base_max_width_px;

    .circle-min {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      font-family: 'Kiwi Maru', serif;
      font-weight: bold;
      font-size: 22px;
    }

    .circle-mid {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      font-family: 'Kiwi Maru', serif;
      font-weight: bold;
      font-size: 22px;
    }

    .circle {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      font-family: 'Kiwi Maru', serif;
      font-weight: bold;
      font-size: 24px;
    }

    .gray {
      background-color: #d6e1e1;
      color: $text_sub !important;
    }

    .pink {
      background-color: $body_sub !important;
      color: #fff !important;
    }
  }

  .agency-fee-input {
    p {
      font-size: 16px;
      font-weight: bold;
      color: $text_primary;
      font-family: 'Kiwi Maru', serif;
    }

    input {
      padding-top: 18px;
      padding-bottom: 18px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      font-family: 'Kiwi Maru', serif;
      height: 56px;
      width: 160px;
    }

    .isSelectedInput {
      background-color: $body_sub;
      color: #fff !important;
      border-radius: 8px;
    }

    .isSelectedInput::placeholder {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      font-family: 'Kiwi Maru', serif;
    }

    .isNotSelectedInput {
      color: $body_sub;
      border: 2px solid #d6e1e1;
      border-radius: 8px;
    }

    .isNotSelectedInput::placeholder {
      color: #d6e1e1;
      font-size: 18px;
      font-weight: bold;
      font-family: 'Kiwi Maru', serif;
    }
  }

  .agency-fee-alert {
    font-size: 14px;
    font-weight: bold;
    color: $text_sub;
    text-align: center;
  }
}

.rounded-btn-active {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: $body_main;
  color: #fff;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

.rounded-btn-inactive {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: #fff;
  color: $text_main;
  border: 2px solid $body_tertiary;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

.rounded-btn-back {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: #fff;
  color: $text_primary;
  border: 2px solid $body_tertiary;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;
  margin-right: 96px;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    budget: Number,
    proppedAgencyFee: Number
  },
  data () {
    return {
      inputBudget: 0,
      agencyFee: null,
      inputPlaceText: null,
      inputAgencyFee: null,
      isValidAgencyFee: true,
      gray: 'gray',
      pink: 'pink',
      minIsSelected: false,
      midIsSelected: false,
      bigIsSelected: false,
      previousSelected: 'mid',
      isSelectedInput: 'isSelectedInput',
      isNotSelectedInput: 'isNotSelectedInput'
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    inputBudget: {
      handler: function (val) {
        if (!isNaN(Number(val)) && Number(val) > 0) {
          this.$emit('budget', Number(val))
        }
      }
    },
    budget: {
      handler: function (val) {
        this.inputBudget = val
      },
      immediate: true
    },
    proppedAgencyFee: {
      handler: function (val) {
        if (val !== this.agencyFee) {
          if (val === 100) {
            this.selectAgencyFee('min')
          } else if (val === 500) {
            this.selectAgencyFee('mid')
          } else if (val === 1000) {
            this.selectAgencyFee('big')
          } else {
            this.inputAgencyFee = val
            this.previousSelected = 'mid'
            this.validateInputAgencyFee()
          }
        }
      },
      immediate: true
    },
    agencyFee: {
      handler: function (val) {
        if (this.agencyFee >= 100) {
          this.$emit('agency-fee', val)
        }
      }
    }
  },
  methods: {
    selectAgencyFee (type) {
      if (this.inputAgencyFee === null && !this.isValidAgencyFee) {
        this.isValidAgencyFee = true
      }
      this.inputAgencyFee = null
      this.previousSelected = type
      switch (type) {
        case 'min':
          this.minIsSelected = true
          this.midIsSelected = false
          this.bigIsSelected = false
          this.agencyFee = 100
          break
        case 'mid':
          this.minIsSelected = false
          this.midIsSelected = true
          this.bigIsSelected = false
          this.agencyFee = 500
          break
        case 'big':
          this.minIsSelected = false
          this.midIsSelected = false
          this.bigIsSelected = true
          this.agencyFee = 1000
          break
      }
    },
    validateInputAgencyFee () {
      if (!isNaN(this.inputAgencyFee) && this.inputAgencyFee >= 100) {
        this.agencyFee = Number(this.inputAgencyFee)
        this.minIsSelected = false
        this.midIsSelected = false
        this.bigIsSelected = false
        this.isValidAgencyFee = true
      } else {
        this.isValidAgencyFee = false
        this.selectAgencyFee(this.previousSelected)
      }
    },
    next () {
      if (this.budget > 0 && this.agencyFee >= 100) {
        this.$emit('next', 3)
      }
    },
    prev () {
      this.$emit('prev', 1)
    }
  }
}
</script>
