<template lang="pug">
  div.wrap-module-order-description.fc.f
    div.order-description.pa-1
      div.order-step-text.text-center
        p.mb-0 Step2
      div.order-description-text
        div.text-center.mt-2
          p.mb-0 どんなことをお願いしますか？
          p.mb-4 複数選択してください。
      div.order-description-select
        div.f.fc.mb-3
          div.middle.mr-2(:class="`${tags.includes(list[0]) ? 'active' : 'inactive'}`" @click="selectTag(list[0])")
            p.mb-0 {{ list[0] }}
          div.middle(:class="`${tags.includes(list[1]) ? 'active' : 'inactive'}`" @click="selectTag(list[1])")
            p.mb-0 {{ list[1] }}
        div.f.fc.mb-3
          div.small.mr-2(:class="`${tags.includes(list[2]) ? 'active' : 'inactive'}`" @click="selectTag(list[2])")
            p.mb-0 {{ list[2] }}
          div.small.mr-2(:class="`${tags.includes(list[3]) ? 'active' : 'inactive'}`" @click="selectTag(list[3])")
            p.mb-0 {{ list[3] }}
          div.small(:class="`${tags.includes(list[4]) ? 'active' : 'inactive'}`" @click="selectTag(list[4])")
            p.mb-0 {{ list[4] }}
        div.f.fc.mb-3
          div.middle.mr-2(:class="`${tags.includes(list[5]) ? 'active' : 'inactive'}`" @click="selectTag(list[5])")
            p.mb-0 {{ list[5] }}
          div.middle(:class="`${tags.includes(list[6]) ? 'active' : 'inactive'}`" @click="selectTag(list[6])")
            p.mb-0 {{ list[6] }}
        div.f.fc.mb-3
          div.middle.mr-2(:class="`${tags.includes(list[7]) ? 'active' : 'inactive'}`" @click="selectTag(list[7])")
            p.mb-0 {{ list[7] }}
          div.middle(:class="`${tags.includes(list[8]) ? 'active' : 'inactive'}`" @click="selectTag(list[8])")
            p.mb-0 {{ list[8] }}
        div.f.fc
          div.middle.mr-2(:class="`${tags.includes(list[9]) ? 'active' : 'inactive'}`" @click="selectTag(list[9])")
            p.mb-0 {{ list[9] }}
          div.middle(:class="`${tags.includes(list[10]) ? 'active' : 'inactive'}`" @click="selectTag(list[10])")
            p.mb-0 {{ list[10] }}
      div.order-description-input.f.fc.mt-5
        div
          p.mb-1.bold 詳細(任意)
          div.f.fc
            div
              textarea(v-model="inputDescription" placeholder="例)ビール6缶、18時までにお願いします。銘柄はサントリーならなんでもOKです。")
              p.mt-1.fz12.order-description-note.mb-0 詳細はカモンさんとマッチした後に
              p.fz12.order-description-note.mb-0 匿名チャットでもお伝えできます。
      div.f.fc.my-8
        div.rounded-btn-back(@click="prev")
          p 戻る
        div.rounded-btn-active(@click="next")
          p 次へ
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap');

.wrap-module-order-description {
  width: 100%;
  max-width: $base_max_width_px;
  margin-top: 80px !important;

  .order-description {
    width: 100%;
    max-width: $base_max_width_px;

    .order-step-text {
      font-size: 22px;
      font-weight: bold;
      color: $text_sub;
      font-family: 'Kiwi Maru', serif;
    }

    .order-description-text {
      font-size: 18px;
      font-weight: bold;
      color: $text_primary;
      font-family: 'Kiwi Maru', serif;
    }
  }

  .order-description-select {
    max-width: $base_max_width_px;

    .middle {
      text-align: center;
      width: 188px;
      font-family: 'Kiwi Maru', serif;
      font-weight: bold;
      border-radius: 12px;
      padding: 4px;
    }

    .small {
      text-align: center;
      min-width: 112px;
      font-family: 'Kiwi Maru', serif;
      font-weight: bold;
      border-radius: 12px;
      padding: 4px;
    }

    .active {
      background-color: $body_sub !important;
      color: #fff !important;
    }

    .inactive {
      border: 2px solid rgba(0, 0, 0, 0.4);
    }
  }

  .order-description-input {
    textarea {
      min-height: 80px;
      width: 368px;
      padding: 8px 16px;
      border-radius: 8px;
      font-weight: bold;
      font-family: 'Kiwi Maru', serif;
      border: 1px solid rgba(0, 0, 0, 0.4);
    }

    .order-description-note {
      color: $text_sub;
      text-align: center;
      font-weight: bold;
    }
  }
}

.rounded-btn-active {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: $body_main;
  color: #fff;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

.rounded-btn-back {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: #fff;
  color: $text_primary;
  border: 2px solid $body_tertiary;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;
  margin-right: 96px;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import ItemBtn from '@/components/item/ItemBtn'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemBtn
  },
  props: {
    tags: Array,
    description: String
  },
  data () {
    return {
      inputDescription: '',
      list: [
        '1店舗でお買い物',
        '2店舗以上でお買い物',
        'テイクアウト',
        '3時間以内に',
        'いつでも',
        '3アイテム以内',
        '4アイテム以上',
        '軽いもの',
        '飲料・お米など重いもの',
        '金額は常識の範囲内でOK',
        'できるだけ安いものを選んで欲しい'
      ],
      smallIndex: [2, 3, 4],
      lineFirstIndex: [0, 2, 5, 7, 9]
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    description: {
      handler (val) {
        console.log(val)
        this.inputDescription = val
      },
      immediate: true
    },
    inputDescription: {
      handler (val) {
        this.emitDescription()
      }
    }
  },
  methods: {
    selectTag (tag) {
      this.$emit('tag', tag)
    },
    emitDescription () {
      if (this.inputDescription.match(/\S/g)) {
        this.$emit('description', this.inputDescription)
      }
    },
    next () {
      this.$emit('next', 2)
    },
    prev () {
      this.$emit('prev', 0)
    }
  }
}
</script>
