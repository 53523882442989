<template lang="pug">
  div.footer-wrapper.f.fc
    div.footer-content.f.flex-between
      div
        p.bold.fz18.mb-1.mt-2.ml-3 Come-On!
        p.fz12.ml-3.mb-1 SpotWORK株式会社
      div.terms.mt-2
        div
          router-link.fz12.mr-2.mb-1(to="/terms/service") 利用規約
        div
          router-link.fz12.mr-2(to="/terms/commerce") 特定商取引法に関する方針
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.footer-wrapper {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 20;
  background: $body_main;

  .footer-content {
    background: $body_main;
    width: 100%;
    max-width: $base_max_width_px;
    color: white;
  }

  .terms {
    a {
      color: white;
      text-decoration: none;
    }
  }
}
</style>

<script>
export default {
}
</script>
