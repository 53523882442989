<template lang="pug">
  div.wrap-module-order-place.fc.f
    div.order-place
      div.order-step-text.mt-6.text-center
        p.mt-4.mb-0 Step1
      div.order-place-text.mt-3
        div.text-center
          p.mt-1.mb-6 どこにお届けしますか？
      div.order-place-select
        ItemPlaceCard.mb-6(v-for="val in opts" :opt="val" @action="changePlace" :key="val.id")
        ItemPlaceCard.mb-6.pr-2(:opt="inputOpt", @action="isShowInput = true")
      div.f.fh.mt-8
        div.rounded-btn-inactive(v-if="!place")
          p 次へ
        div.rounded-btn-active(v-if="place" @click="next")
          p 次へ
      div.order-place-steps
        img(src="@/assets/walker.png")
      v-dialog(v-model="isShowInput")
        v-card.input-card
          p.ml-4.bold 場所の入力
          p.fz12.ml-4.mb-0.bold 都道府県
          v-text-field.input-place(outlined dense placeholder="東京都" v-model="inputPrefecture" persistent-hint)
          p.fz12.ml-4.mb-0.bold 市区町村
          v-text-field.input-place(outlined dense placeholder="中央区" v-model="inputCity" persistent-hint)
          p.fz12.ml-4.mb-0.bold 町域・番地
          v-text-field.input-place(outlined dense placeholder="築地１丁目１２−１２" v-model="inputAddress" persistent-hint)
          p.fz12.ml-4.mb-0.bold 建物名など
          v-text-field.input-place(outlined dense placeholder="SIビル５階" hint="マンション等にお住みの方はご記入ください" v-model="inputBuilding" persistent-hint)
          div.text-center
            v-btn.text-center.mt-4(@click="createAddress" color="#e7305b")
              span 入力

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap');

.wrap-module-order-place {
  width: 100%;
  max-width: $base_max_width_px;
  margin-top: 80px !important;

  .order-place {
    width: 100%;
    max-width: $base_max_width_px;

    .order-step-text {
      font-size: 22px;
      font-weight: bold;
      color: $text_sub;
      font-family: 'Kiwi Maru', serif;
    }

    .order-place-text {
      font-size: 18px;
      font-weight: bold;
      color: $text_primary;
      font-family: 'Kiwi Maru', serif;
    }

  }

  .order-place-select {
    max-width: $base_max_width_px;
  }

  .order-place-steps {
    margin-top: 128px;
    width: 100%;
    max-width: $base_max_width_px;
    background-image: url("/img/city.png");
    background-size: cover;

    img {
      position: relative;
      left: calc(50% - 175px);
      height: 120px;
    }
  }
}

.alert {
  color: $text_accent;
}

.input-card {
    min-width: 300px;
    padding-top: 32px;
    padding-bottom: 32px;

    span {
      color: #fff;
      font-weight: bold;
    }
  }

.input-place {
  margin-left: 5% !important;
  margin-right: 5% !important;

  .place-btn {
    position: relative;
    left: calc(100% - 60px);
  }
}

.rounded-btn-active {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: $body_main;
  color: #fff;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

.rounded-btn-inactive {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: #fff;
  color: $text_main;
  border: 2px solid $body_tertiary;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import { gmapApi } from 'vue2-google-maps'
import ItemPlaceCard from '@/components/item/ItemPlaceCard'
import ItemBtn from '@/components/item/ItemBtn'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemPlaceCard,
    ItemBtn
  },
  props: {
    opts: {
      type: Array
    },
    place: Object
  },
  data () {
    return {
      inputOpt: {
        value: { address: '', type: '入力する', lat: null, lng: null },
        text: '入力する',
        subText: '',
        active: false
      },
      inputSelected: false,
      defaultSelected: false,
      inputPlaceText: null,
      inputPrefecture: null,
      inputCity: null,
      inputAddress: null,
      inputBuilding: null,
      isShowInput: false,
      geocoder: {}
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    place: {
      handler: function (val) {
        if (val.text === '入力する') {
          this.inputOpt = val
          this.inputOpt.active = true
        } else {
          this.inputOpt.active = false
        }
      },
      immediate: true
    },
    isShowInput: {
      handler: function (val) {
        if (!val) {
          const place = this.inputOpt.value
          console.log(place)
          if (this.place.type !== place.type) {
            if (place.lat && place.lng && place.address) {
              this.$emit('place', this.inputOpt)
            } else {
              this.inputOpt.active = false
              this.isShowInput = true
            }
          }
        }
      }
    }
  },
  async mounted () {
    await this.$gmapApiPromiseLazy().then(() => {
      this.geocoder = new google.maps.Geocoder()
    })
  },
  methods: {
    changePlace (place) {
      const prevIndex = this.opts.findIndex(e => e.value.type === this.place.type)
      const currentIndex = this.opts.findIndex(e => e.value.type === place.type)
      if (prevIndex < 0) {
        this.inputOpt.active = false
      }
      if (currentIndex < 0) {
        this.$emit('place', place)
      }
    },
    async createAddress () {
      if (!this.inputPrefecture || !this.inputCity || !this.inputAddress) {
        window.alert('都道府県から番地(または建物)まで正しく住所を入力してください。')
        return
      }
      if (this.inputBuilding) {
        this.inputPlaceText = this.inputPrefecture + this.inputCity + this.inputAddress + this.inputBuilding
      } else {
        this.inputPlaceText = this.inputPrefecture + this.inputCity + this.inputAddress
      }
      const self = this
      await this.geocoder.geocode({
        address: this.inputPlaceText
      }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const place = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            address: self.inputPlaceText,
            type: '入力する'
          }
          self.inputOpt.value = place
          self.inputOpt.subText = self.inputPlaceText
          self.isShowInput = false
        } else {
          window.alert('住所が正しく登録できませんでした。もう一度お試しください')
        }
      })
      this.isShowInput = false
    },
    next () {
      this.$emit('next', 1)
    }
  }
}
</script>
