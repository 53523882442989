<template lang="pug">
  div.wrap-module-order-confirm.fc.f
    div.order-confirm
      div.order-step-text.text-center.mt-4
        p.mb-0 Step4
      div.order-confirm-text
        div.text-center.mt-2
          p.mb-8 内容とお支払い方法をご確認ください。
      div.f.fc
        div
          div.order-confirm-container
            div.order-confirm-label.f.flex-between.mb2
              p.mb0 ■依頼内容
              p.mb0.change-btn(@click="changePage(1)") 変更する
            p.order-confirm-content {{ `${order.tags.length >= 1 ? order.tags.join('、') : 'なし'}` }}
          div.order-confirm-container
            div.order-confirm-label.f.flex-between.mb2
              p.mb0 ■詳細
              p.mb0.change-btn(@click="changePage(1)") 変更する
            p.order-confirm-content {{ `${order.description !== '' ? order.description : 'なし'}` }}
          div.order-confirm-container
            div.order-confirm-label.f.flex-between.mb2
              p.mb0 ■お届け場所
              p.mb0.change-btn(@click="changePage(0)") 変更する
            p.order-confirm-content {{ order.place.value.address }}
          div.order-confirm-container
            div.order-confirm-label.f.flex-between.mb2
              p.mb0 ■お支払い金額
              p.mb0.change-btn(@click="changePage(2)") 変更する
            div.order-confirm-payment-note.mb-2.fz14
              p ※お買い物の商品代金は変動するため、
              p あくまで目安の金額になります。
            p.order-confirm-payment-sum.fz18.mb-1 合計：{{ displayFeeSum() }}円
            p.order-confirm-payment-detail.mb0 内訳
            p.order-confirm-payment-detail.mb0 {{`・商品代金：${order.budget} 円`}}
              span （目安）
            p.order-confirm-payment-detail.mb0 {{`・代行料金：${order.agencyFee} 円`}}
            p.order-confirm-payment-detail.mb0 {{`・システム利用料：${order.serviceFee} 円`}}
              span （システム手数料はカード決済されます）
            p.order-confirm-payment-detail.mb0(v-if="!order.payOnlyCash") {{`・カード決済手数料：${displayCardFee()} 円`}}
              span （商品代金の9%）
      div.order-payment-method.f.fc.mb-4
        div
          div.f.fc.mb-1
            div.order-payment-method-label.f.fc
              p.mb-0.mr-1.fz18 決済方法
              p.mb-1.needed.fz14.px-2.py1 必須
          p.fz14.mb-4.bold.text-center ※お支払いは、カード or 現金が選べます。
          div.order-payment-method-content.f.flex-middle.mb-3(@click="() => {emitPayOnlyCash(false)}")
            div.image
              img(:src="require(`@/assets/checkbox${!order.payOnlyCash ? '' : '-inactive'}.png`)")
            p(:style="`color: ${!order.payOnlyCash ? '#2a2a2a' : '#bdbdbd'};`") カード決済料：お買い物代金の９％
          div.order-payment-method-content.f(@click="() => {emitPayOnlyCash(true)}" :class="order.payOnlyCash ? '#2a2a2a' : '#bdbdbd'")
            div.image
              img(:src="require(`@/assets/checkbox${order.payOnlyCash ? '' : '-inactive'}.png`)")
            div
              p(:style="`color: ${order.payOnlyCash ? '#2a2a2a' : '#bdbdbd'};`") お買い物代金を現金払いにする。
              p.order-payment-method-note カード決済代行料9%は不要です。
      div.f.fc.my-8
        div.rounded-btn-back(@click="prev")
          p 戻る
        div.rounded-btn-active(@click="emitPayment")
          p 依頼
      div.order-confirm-steps
        img(src="@/assets/walker.png")

</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap');

.wrap-module-order-confirm {
  width: 100%;
  max-width: $base_max_width_px;
  margin-top: 80px !important;

  .order-confirm {
    width: 100%;
    max-width: $base_max_width_px;

    .order-step-text {
      font-size: 22px;
      font-weight: bold;
      color: $text_sub;
      font-family: 'Kiwi Maru', serif;
    }

    .order-confirm-text {
      font-size: 20px;
      font-weight: bold;
      color: $text_primary;
      font-family: 'Kiwi Maru', serif;
    }

    .order-confirm-container {
      max-width: 360px;
      margin-bottom: 16px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      font-weight: bold;
      color: $text_primary;
      font-family: 'Kiwi Maru', serif;

      .change-btn {
        color: #0000AE;
      }

      .order-confirm-payment-note {
        text-align: left;
        p {
          margin-bottom: 0px;
        }
      }

      .order-confirm-content {
        margin-bottom: 0px;
      }

      span {
        font-size: 12px;
      }
    }

    .order-payment-method {

      .order-payment-method-label {
        font-weight: bold;
        color: $text_primary;
        width: 124px;
        border-bottom: 2px solid $body_sub;

        .needed {
          background-color: $body_sub;
          color: #fff;
          font-family: 'Kiwi Maru', serif;
        }
      }

      .order-payment-method-content {
        max-width: 360px;
        font-size: 18px;
        font-family: 'Kiwi Maru', serif;
        font-weight: bold;

        p {
          margin-left: 8px;
          margin-bottom: 0px;
          max-width: 314px;
        }

        .image {
          width: 40px;
          height: 40px;
        }

        .order-payment-method-note {
          color: $text_sub;
          font-size: 14px;
        }
      }
    }
  }

  .order-confirm-steps {
    position: relative;
    width: 100%;
    bottom: 0px;
    max-width: $base_max_width_px;
    overflow: hidden;
    background-image: url("/img/city.png");
    background-size: cover;

    img {
      position: relative;
      left: calc(70% + 30px);
      height: 120px;
    }
  }
}

.rounded-btn-active {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: $body_main;
  color: #fff;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

.rounded-btn-back {
  height: 72px;
  width: 72px;
  border-radius: 36px;
  cursor: pointer;
  background-color: #fff;
  color: $text_primary;
  border: 2px solid $body_tertiary;
  font-size: 18px;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;
  margin-right: 96px;

  p {
    text-align: center;
    margin: 0px;
    line-height: 72px;
  }
}

</style>

<script>
import { functions } from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import MinNowLoading from '@/components/shared/MinNowLoading'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    MinNowLoading
  },
  props: {
    order: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    displayFeeSum () {
      if (this.order.payOnlyCash) {
        return this.order.serviceFee + this.order.budget + this.order.agencyFee
      } else {
        return this.order.serviceFee + Math.floor(this.order.budget * 1.09) + this.order.agencyFee
      }
    },
    changePage (index) {
      this.$emit('change-page', index)
    },
    displayCardFee () {
      return Math.floor(this.order.budget * 0.09)
    },
    prev () {
      this.$emit('prev', 2)
    },
    emitPayOnlyCash (bool) {
      this.$emit('cash', bool)
    },
    emitPayment () {
      this.$emit('payment')
    }
  }
}
</script>
