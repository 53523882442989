<template lang="pug">
  div(@click="$emit('action', opt)" :style="css").wrap-item-order-create-option
    div.py-2
      p.fz24.bold.mb2.ml-3 {{opt.text}}
      p.fz14.px-4.mb-0(v-if="opt.subText") {{ opt.subText }}
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-item-order-create-option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 70%;
  min-height: 76px;
  border-radius: 20px;
  text-align: center;
  font-family: 'Kiwi Maru', serif;
  font-weight: bold;
  cursor: pointer;
  span {
    color: $text_secondary;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  props: {
    opt: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      css: {
        border: '2px solid rgba(0, 0, 0, 0.4)',
        color: '#2a2a2a',
        backgroundColor: '#ffffff'
      },
      clippedSubText: ''
    }
  },
  watch: {
    opt: {
      handler: function (val) {
        if (val.active) {
          const css = {
            border: '2px solid #e7305b',
            color: '#ffffff',
            backgroundColor: '#e7305b'
          }
          this.css = css
        } else {
          const css = {
            border: '2px solid rgba(0, 0, 0, 0.4)',
            color: '#2a2a2a',
            backgroundColor: '#ffffff'
          }
          this.css = css
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  }
}
</script>
