<template lang="pug">
  div
    div.stepper.f
      div.text-center(v-if="isFirstActivate")
        div.step.active
          span.bold.block 1
        p.progress-text-active(v-if="isFirstActivate") 届け先
      div.text-center(v-else @click="changeCurrentIndex(0)")
        div.step.inactive
          span.bold.block 1
        p.progress-text-inactive(v-if="!isFirstActivate") 届け先
      div.divider
      div.text-center(v-if="isSecondActivate")
        div.step.active
          span.bold.block 2
        p.progress-text-active(v-if="isSecondActivate") 依頼
      div.text-center(v-else @click="changeCurrentIndex(1)")
        div.step.inactive
          span.bold.block 2
        p.progress-text-inactive(v-if="!isSecondActivate") 依頼
      div.divider
      div.text-center(v-if="isThirdActivate")
        div.step.active
          span.bold.block 3
        p.progress-text-active(v-if="isThirdActivate") 予算
      div.text-center(v-else @click="changeCurrentIndex(2)")
        div.step.inactive
          span.bold.block 3
        p.progress-text-inactive(v-if="!isThirdActivate") 予算
      div.divider
      div.text-center(v-if="isFourthActivate")
        div.step.active
          span.bold.block 4
        p.progress-text-active(v-if="isFourthActivate") 確認
      div.text-center(v-else @click="changeCurrentIndex(3)")
        div.step.inactive
          span.bold.block 4
        p.progress-text-inactive(v-if="!isFourthActivate") 確認
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.stepper {
  position: absolute;
  left: calc(50% - 156px);
  top: 64px;

  .active {
    background-color: $body_main
  }

  .inactive {
    background-color: $body_tertiary;
  }
}

.step {
  width: 42px;
  height: 42px;
  border-radius: 21px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  span {
    padding-top: 3px;
  }
}

.divider {
  width: 48px;
  height: 1px;
  margin-top: 18px;
  border: solid 1px $body_tertiary;
}

.progress-text-active {
  font-family: 'Kiwi Maru', serif;
  background-color: none;
  color: $text_main;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}

.progress-text-inactive {
    background-color: none;
    font-family: 'Kiwi Maru', serif;
    color: $text_tertiary;
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 14px;
}

</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  props: {
    activeNumber: {
      Number,
      required: true
    }
  },
  data () {
    return {
      isFirstActivate: false,
      isSecondActivate: false,
      isThirdActivate: false,
      isFourthActivate: false,
      isFifthActivate: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    activeNumber: {
      handler: function (val, oldVal) {
        this.changeActiveStep(val, oldVal)
      },
      immediate: true
    }
  },
  methods: {
    changeActiveStep (val, oldVal) {
      switch (val) {
        case 0:
          this.isFirstActivate = true
          break
        case 1:
          this.isSecondActivate = true
          break
        case 2:
          this.isThirdActivate = true
          break
        case 3:
          this.isFourthActivate = true
          break
        case 4:
          this.isFifthActivate = true
          break
      }
      switch (oldVal) {
        case 0:
          this.isFirstActivate = false
          break
        case 1:
          this.isSecondActivate = false
          break
        case 2:
          this.isThirdActivate = false
          break
        case 3:
          this.isFourthActivate = false
          break
        case 4:
          this.isFifthActivate = false
          break
        default:
          break
      }
    },
    changeCurrentIndex (index) {
      this.$emit('emit-index', index)
    }
  }
}
</script>
